import { IconButton, styled } from "@mui/material";

export const StyledMailButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  zIndex: 100,
  right: 32,
  bottom: 32,
  "&,:hover": {
    backgroundColor: "var(--green-500)",
  },
  width: 70,
  height: 70,
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  svg: {
    width: 37,
    height: 37,
  },
  [theme.breakpoints.down("md")]: {
    width: 50,
    height: 50,
    svg: {
      width: 26,
      height: 26,
    },
    right: 16,
    bottom: 16,
  },
}));
