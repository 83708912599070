import { Button, ButtonProps, Dialog, IconButton, IconButtonProps, styled } from "@mui/material";
import {
  CircleChatIcon,
  EmailIcon,
  FlagIcon,
  GOOGLE_ICON,
  SellBagIcon,
} from "assets/icons";
import { OpenTabLink, ReportForm, StyledATag, XsTextSemiBold } from "components";
import { PATHS } from "constants/routes";
import { GG_APP_ID } from "constants/schemas";
import useChat from "hooks/useChat";
import useUser from "hooks/useUser";
import { useLoading } from "providers/loading";
import { MouseEvent, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import { apiPurchaseContact } from "services/api/contact";
import { apiGetUserBalance } from "services/api/user";
import { updateUserContactQuote } from "services/redux/actions/user";
import { StyledMailButton } from "./Button.styles";

const ConfirmButton = (props: ButtonProps) => {
  return <StyledConfirmButton {...props} />;
};

const AddButton = (props: ButtonProps) => {
  return <StyledAddButton {...props} />;
};

const CancelButton = (props: ButtonProps) => {
  return <StyledCancelButton {...props} />;
};

const RoundButton = (props: ButtonProps) => {
  return <StyledRoundButton {...props} />;
};

const AcceptButton = (props: ButtonProps) => {
  return <StyledAcceptButton {...props} />;
};

const YellowButton = (props: ButtonProps) => {
  return <StyledConfirmButton {...props} />;
};

const BaseButton = (props: ButtonProps) => {
  return <StyledBaseButton {...props} />;
};

const TextButton = (props: ButtonProps) => {
  return <StyledTextButton variant="text" {...props} />;
};

const DeleteButton = (props: ButtonProps) => {
  return <StyledDeleteButton {...props} />;
};

export const GreenButton = (props: ButtonProps) => {
  return <StyledAcceptButton {...props} />;
};

export const GoogleButton = (props: ButtonProps & { callback: any }) => {
  const redirect_uri = `${window.location.protocol}//${window.location.host}`;

  const onConfirm = useCallback(props?.callback, []);

  return (
    <LoginSocialGoogle
      client_id={GG_APP_ID || ""}
      redirect_uri={redirect_uri}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      access_type="offline"
      onResolve={({ data }: IResolveParams) => {
        onConfirm({ ...data, redirect_uri });
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <StyledSocialButton
        startIcon={<img src={GOOGLE_ICON} alt="icon" />}
        {...props}
      >
        Google
      </StyledSocialButton>
    </LoginSocialGoogle>
  );
};

export const LgAcceptButton = (props: ButtonProps) => {
  return <StyledLgAcceptButton {...props} />;
};

export const SendMessageButton = ({
  identity,
  hideIcon,
}: {
  identity?: string;
  hideIcon?: boolean;
}) => {
  const { onAddChatToShowList } = useChat();
  const t = useIntl();

  const onClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (identity) onAddChatToShowList(identity);
  };

  return (
    <AcceptButton
      onClick={onClickButton}
      endIcon={hideIcon ? undefined : <CircleChatIcon color="#fff" />}
      className="send-msg-btn"
    >
      {t.formatMessage({ id: "send_message" })}
    </AcceptButton>
  );
};

export const ReportButton = ({
  mobile,
  type,
  title,
}: {
  mobile?: boolean;
  type: string;
  title?: string;
}) => {
  const t = useIntl();
  const [showReport, setShowReport] = useState(false);

  const onCloseReport = () => setShowReport(false);

  return (
    <>
      {!mobile && (
        <IconButton className="fit" onClick={() => setShowReport(true)}>
          <FlagIcon />
        </IconButton>
      )}
      {mobile && (
        <StyledReportButton
          endIcon={<FlagIcon />}
          onClick={() => setShowReport(true)}
        >
          {t.formatMessage({ id: "report" })}
        </StyledReportButton>
      )}

      <Dialog open={showReport} onClose={onCloseReport}>
        <ReportForm onClose={onCloseReport} {...{ mobile, type, title }} />
      </Dialog>
    </>
  );
};

export const GetContactButton = (
  props: ButtonProps & { onBonusGetContact?: () => void; contactData?: any }
) => {
  const t = useIntl();
  const { userContactQuote } = useUser();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();

  const onClickSendContact = async () => {
    if (userContactQuote > 0) {
      try {
        setLoading(true);
        await apiPurchaseContact(props.contactData);
        props.onBonusGetContact?.();
        apiGetUserBalance().then((res) => {
          dispatch(
            updateUserContactQuote(res.data.message?.data?.contact_quota)
          );
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <AcceptButton onClick={onClickSendContact} {...props}>
      {t.formatMessage({ id: "get_contact" })}

      <XsTextSemiBold className="chip quote">
        - 1 {t.formatMessage({ id: "contact" })} / {userContactQuote}{" "}
        {t.formatMessage({
          id: userContactQuote > 1 ? "contacts_lowercase" : "contact",
        })}
      </XsTextSemiBold>
    </AcceptButton>
  );
};

export const SellingButton = (props: ButtonProps) => {
  const t = useIntl();
  const { isLoggedIn } = useUser();
  return (
    <StyledATag href={PATHS.bizDomain}>
      <StyledTextButton
        variant="text"
        style={{ color: "var(--green-600)" }}
        startIcon={<SellBagIcon />}
        {...props}
      >
        {t.formatMessage({ id: isLoggedIn ? "seller_center" : "StartSelling" })}
      </StyledTextButton>
    </StyledATag>
  );
};

export const MailButton = (props: IconButtonProps) => {
  return (
    <OpenTabLink href={PATHS.contactUs}>
      <StyledMailButton children={<EmailIcon color="#fff" />} {...props} />
    </OpenTabLink>
  );
};

const ButtonBase = styled(Button)({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: "bold",
  padding: "8px 16px",
  fontSize: 14,
});

const StyledConfirmButton = styled(Button)({
  borderRadius: 8,
  width: "100%",
  fontWeight: 600,
  textTransform: "initial",
  padding: "8px 16px",
  color: "#fff",
  "&": {
    background: "var(--color)",
  },
  ":hover": {
    background: "var(--yellow-600)",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26) !important",
  },
});

const StyledSocialButton = styled(Button)({
  border: "1px solid rgba(0,0,0,.26)",
  textTransform: "initial",
  color: "#000",
  textAlign: "center",
  "& .MuiButton-startIcon img": {
    width: 32,
  },
});

const StyledAddButton = styled(BaseButton)({
  color: "#fff",
  "&,:hover": {
    backgroundColor: "var(--sub-color)",
  },
  span: {
    marginRight: 4,
  },
});

const StyledDeleteButton = styled(BaseButton)({
  border: "none",
  color: "#fff",
  "&,:hover": {
    backgroundColor: "#D92D20",
  },
});

const StyledCancelButton = styled(ButtonBase)({
  color: "#000",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});

const StyledRoundButton = styled(ButtonBase)({
  color: "#000",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});

const StyledAcceptButton = styled(ButtonBase)({
  color: "#fff",
  "&": {
    backgroundColor: "var(--sub-color)",
  },
  "&:hover": {
    backgroundColor: "var(--green-800)",
  },
  "&.Mui-disabled": {
    backgroundColor: "#D0D5DD",
    color: "#fff",
  },
});

const StyledBaseButton = styled(ButtonBase)({
  color: "#000",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
});

const StyledLgAcceptButton = styled(StyledAcceptButton)({
  fontWeight: 600,
  padding: "10px 18px",
});

const StyledTextButton = styled(Button)({
  textTransform: "initial",
  padding: 0,
  fontWeight: 600,
  fontSize: 16,
  // ":hover": {
  //   fontWeight: 700,
  // },
  ".MuiButton-icon": {
    marginLeft: 0,
  },
});

const StyledReportButton = styled(BaseButton)({
  svg: {
    width: 20,
    height: 20,
  },
  color: "var(--gray)",
  width: "fit-content",
  lineHeight: "20px",
});

export {
  ConfirmButton,
  AddButton,
  CancelButton,
  AcceptButton,
  BaseButton,
  RoundButton,
  TextButton,
  DeleteButton,
  YellowButton,
};
